<template>
    <div class="service-page">
      <h1>AI Solutions</h1>
      <p>AI-powered solutions to enhance efficiency and automation.</p>
      <img src="@/assets/ai-solutions.jpg" alt="AI Solutions" />
      <p>Our AI services include predictive analytics, machine learning, and automation tools tailored to your business needs.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "AISolutionsPage",
  };
  </script>
  
  <style scoped>
  .service-page {
    padding: 20px;
    text-align: center;
  }
  
  h1 {
    color: #004e92;
  }
  
  p {
    font-size: 16px;
    color: #333;
  }
  
  img {
    max-width: 100%;
    border-radius: 10px;
    margin: 20px 0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  </style>