<template>
    <div class="service-page">
      <h1>IoT Solutions</h1>
      <p>Connecting smart devices for seamless automation and insights.</p>
      <img src="@/assets/iot-solutions.jpg" alt="IoT Solutions" />
      <p>Leverage our IoT solutions to connect and manage devices efficiently, optimizing workflows and real-time data insights.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "IoTSolutionsPage",
  };
  </script>
  
  <style scoped>
  .service-page {
    padding: 20px;
    text-align: center;
  }
  
  h1 {
    color: #004e92;
  }
  
  p {
    font-size: 16px;
    color: #333;
  }
  
  img {
    max-width: 100%;
    border-radius: 10px;
    margin: 20px 0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  </style>
  