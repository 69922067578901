<template>
    <div class="service-page">
      <h1>Virtual Reality Experiences</h1>
      <p>Immersive VR simulations and content creation.</p>
      <video src="@/assets/vr-demo.mp4" autoplay muted loop playsinline controls></video>
      <p>Deliver unforgettable experiences with VR simulations designed for training, storytelling, and gaming.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "VRExperiencesPage",
  };
  </script>
  
  <style scoped>
  .service-page {
    padding: 20px;
    text-align: center;
  }
  
  h1 {
    color: #004e92;
  }
  
  p {
    font-size: 16px;
    color: #333;
  }
  
  video {
    max-width: 100%;
    border-radius: 10px;
    margin: 20px 0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  </style>