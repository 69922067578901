<template>
    <div class="service-page">
      <h1>Hologram Solutions</h1>
      <p>Create immersive holographic displays to captivate your audience.</p>
      <video src="@/assets/hologram-demo.mp4" autoplay muted loop playsinline controls></video>
      <p>Engage audiences with our cutting-edge holographic technology that creates lifelike displays for events and exhibitions.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "HologramSolutionsPage",
  };
  </script>
  
  <style scoped>
  .service-page {
    padding: 20px;
    text-align: center;
  }
  
  h1 {
    color: #004e92;
  }
  
  p {
    font-size: 16px;
    color: #333;
  }
  
  video {
    max-width: 100%;
    border-radius: 10px;
    margin: 20px 0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  </style>
  