<template>
    <div class="login-page">
      <div class="login-container">
        <h1>Login</h1>
        <form @submit.prevent="handleLogin">
          <!-- Email Input -->
          <div class="form-group">
            <label for="email">Email:</label>
            <input
              type="email"
              id="email"
              v-model="email"
              placeholder="Enter your email"
              required
            />
          </div>
  
          <!-- Password Input -->
          <div class="form-group">
            <label for="password">Password:</label>
            <input
              type="password"
              id="password"
              v-model="password"
              placeholder="Enter your password"
              required
            />
          </div>
  
          <!-- Login Button -->
          <div class="form-actions">
            <button type="submit" class="login-button">Login</button>
          </div>
  
          <!-- Forgot Password -->
          <div class="forgot-password-container">
            <a href="#" class="forgot-password">Forgot Password?</a>
          </div>
  
          <!-- OR Divider -->
          <div class="divider">
            <span>OR</span>
          </div>
  
          <!-- Social Logins -->
          <div class="social-login">
            <button class="social-button google">
              <img src="@/assets/google-icon.png" alt="Google" />
              Login with Google
            </button>
            <button class="social-button facebook">
              <img src="@/assets/facebook-icon.png" alt="Facebook" />
              Login with Facebook
            </button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "LoginPage",
    data() {
      return {
        email: "",
        password: "",
      };
    },
    methods: {
      handleLogin() {
        alert(`Logging in with ${this.email}`);
        // Add your authentication logic here
      },
    },
  };
  </script>
  
  <style scoped>
  /* General Styles */
  .login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(120deg, #004e92, #000428);
  }
  
  .login-container {
    background: white;
    padding: 30px;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  h1 {
    color: #004e92;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  label {
    display: block;
    font-size: 14px;
    color: #333;
    margin-bottom: 5px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  input:focus {
    outline: none;
    border-color: #004e92;
  }
  
  /* Login Button */
  .login-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: white;
    background: #004e92;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .login-button:hover {
    background: #003973;
  }
  
  /* Forgot Password */
  .forgot-password-container {
    text-align: left; /* Align to the left */
    margin-top: 10px; /* Add spacing between the button and the link */
  }
  
  .forgot-password {
    font-size: 14px;
    color: #004e92;
    text-decoration: none;
  }
  
  .forgot-password:hover {
    text-decoration: underline;
  }
  
  /* Divider */
  .divider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    position: relative;
  }
  
  .divider span {
    font-size: 14px;
    color: #666;
    background: white;
    padding: 0 10px;
    position: relative;
    z-index: 1;
  }
  
  .divider:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #ddd;
    z-index: 0;
  }
  
  /* Social Login Buttons */
  .social-login {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .social-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .social-button img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
  .social-button.google {
    background: #fff;
    border: 1px solid #ccc;
    color: #333;
  }
  
  .social-button.google:hover {
    border-color: #888;
  }
  
  .social-button.facebook {
    background: #3b5998;
    color: white;
  }
  
  .social-button.facebook:hover {
    background: #2d4373;
  }
  </style>