<template>
    <div class="service-page">
      <h1>Web Applications</h1>
      <p>Designing scalable and high-performance web apps.</p>
      <img src="@/assets/web-applications.jpg" alt="Web Applications" />
      <p>Our web development services focus on security, scalability, and user-friendly designs to meet your business challenges.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "WebApplicationsPage",
  };
  </script>
  
  <style scoped>
  .service-page {
    padding: 20px;
    text-align: center;
  }
  
  h1 {
    color: #004e92;
  }
  
  p {
    font-size: 16px;
    color: #333;
  }
  
  img {
    max-width: 100%;
    border-radius: 10px;
    margin: 20px 0;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  </style>