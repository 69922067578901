<template>
  <div class="service-detail-page">
    <div class="header-section">
      <h1>{{ serviceTitle }}</h1>
      <p>{{ serviceDescription }}</p>
    </div>
    <div class="media-section">
      <img
        v-if="serviceMedia.type === 'image'"
        :src="serviceMedia.src"
        :alt="serviceTitle"
      />
      <video
  v-else-if="serviceMedia.type === 'video'"
  :src="serviceMedia.src"
  autoplay
  muted
  loop
  controls
/>
    </div>
    <div class="content-section">
      <h2>What We Offer</h2>
      <p>{{ serviceContent }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceDetailPage",
  props: ["service"],
  data() {
    return {
      preloadedMedia: null, // For preloading media files
    };
  },
  computed: {
    serviceTitle() {
      const titles = {
        "ai-solutions": "AI Solutions",
        "iot-solutions": "IoT Solutions",
        "hologram-solutions": "Hologram Solutions",
        "on-ground-installations": "On Ground Installations",
        "ar-experiences": "Augmented Reality Experiences",
        "vr-experiences": "Virtual Reality Experiences",
        "web-applications": "Web Applications",
        "mobile-applications": "Mobile Applications",
        "interactive-screens": "Interactive Screens Experiences",
        "mobile-games": "Mobile Games",
        "tech-events": "Tech Events Productions",
      };
      return titles[this.service] || "Service";
    },
    serviceDescription() {
      const descriptions = {
        "ai-solutions": "Explore the power of Artificial Intelligence to revolutionize your business processes.",
        "iot-solutions": "Leverage IoT for smarter, connected systems across industries.",
        "hologram-solutions": "Create immersive holographic displays to captivate your audience.",
        "ar-experiences": "Engage customers with cutting-edge Augmented Reality experiences.",
        "vr-experiences": "Deliver unforgettable Virtual Reality experiences with our solutions.",
      };
      return descriptions[this.service] || "Learn more about our services.";
    },
    serviceMedia() {
   const media = {
     "ai-solutions": { type: "image", src: "/assets/ai-solutions.jpg" },
     "iot-solutions": { type: "image", src: "/assets/iot-solutions.jpg" },
     "hologram-solutions": { type: "video", src: "/assets/hologram-demo.mp4" },
     "ar-experiences": { type: "video", src: "/assets/ar-experiences.mp4" },
     "vr-experiences": { type: "video", src: "/assets/vr-demo.mp4" },
   };
   return media[this.service] || { type: "image", src: "/assets/default-service.jpg" };
},
    serviceContent() {
      const content = {
        "ai-solutions": "Our AI solutions include machine learning, predictive analytics, and automation tools to enhance business efficiency and decision-making processes.",
        "iot-solutions": "With our IoT solutions, connect devices seamlessly, automate operations, and gain real-time insights to optimize your workflows.",
        "hologram-solutions": "Deliver stunning and immersive holographic experiences to engage your audience in creative and interactive ways.",
        "ar-experiences": "Develop AR applications and tools that enhance customer engagement, learning, and interaction in retail, healthcare, and education.",
        "vr-experiences": "Transform the way you tell stories with our VR solutions, offering immersive worlds for gaming, training, or simulations.",
      };
      return content[this.service] || "We offer innovative and tailored solutions to meet your needs.";
    },
  },
  methods: {
    preloadMedia() {
      const mediaElement =
        this.serviceMedia.type === "video"
          ? document.createElement("video")
          : document.createElement("img");
      mediaElement.src = this.serviceMedia.src;
      mediaElement.onload = () => {
        console.log(`${this.serviceMedia.src} preloaded`);
      };
      this.preloadedMedia = mediaElement;
    },
  },
  mounted() {
    this.preloadMedia();
  },
};
</script>

<style scoped>
.service-detail-page {
  padding: 20px;
  text-align: center;
}

.header-section {
  margin-bottom: 20px;
}

h1 {
  color: #004e92;
  margin-bottom: 10px;
}

p {
  font-size: 18px;
  color: #333;
}

.media-section img,
.media-section video {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.content-section {
  margin-top: 20px;
}

.content-section h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.content-section p {
  font-size: 16px;
  line-height: 1.6;
}
</style>