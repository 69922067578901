<template>
    <div class="about-page">
      <h1>About Us</h1>
      <p>We provide creative technology solutions in AR, VR, IoT, and more.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "AboutPage",
  };
  </script>