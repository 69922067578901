<template>
  <div class="home-video-container">
    <video autoplay muted loop>
      <source src="@/assets/homepage-background.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div class="home-logo-overlay">
     
      <h1>Welcome to Our Creative Tech Solutions</h1>
      <img src="@/assets/logoo.png" alt="Tech Solutions Logo" />
    </div>
  </div>
</template>

<style scoped>
.home-video-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.home-video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.home-logo-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 10;
}

.home-logo-overlay img {
  max-width: 450px;
  margin-bottom: 20px;
  opacity: 0.9;
}

.home-logo-overlay h1 {
  font-size: 48px;
  margin: 0;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}
</style>