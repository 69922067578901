<template>
    <div class="subscription-page">
      <div class="toggle-container">
        <!-- Toggle Button -->
        <div class="toggle">
          <button
            :class="{ active: isMonthly }"
            @click="switchToMonthly"
          >
            Monthly
          </button>
          <button
            :class="{ active: !isMonthly }"
            @click="switchToYearly"
          >
            Yearly <span class="discount">30% OFF</span>
          </button>
        </div>
      </div>
  
      <div class="subscription-cards">
        <!-- Subscription Plans -->
        <div
          v-for="plan in displayedPlans"
          :key="plan.id"
          :class="['subscription-card', { selected: selectedPlan === plan.id }]"
          @click="selectPlan(plan.id)"
        >
          <div class="plan-header">
            <h2>{{ plan.name }}</h2>
            <p>{{ plan.description }}</p>
          </div>
          <div class="plan-price">
            <h3>{{ plan.price }} /month</h3>
            <p>Billed {{ isMonthly ? 'monthly' : 'yearly' }}</p>
          </div>
          <div class="plan-features">
            <h4>What's included</h4>
            <ul>
              <li v-for="feature in plan.features" :key="feature">
                ✔ {{ feature }}
              </li>
            </ul>
          </div>
          <div class="button-container">
            <button class="get-started">Get Started</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "SubscriptionPage",
    data() {
      return {
        isMonthly: true, // Tracks whether monthly or yearly is selected
        selectedPlan: null, // Tracks the selected plan
        plans: [
          {
            id: 1,
            name: "Basic",
            description: "Essential tools for individual creators starting their journey.",
            price: "$15",
            yearlyPrice: "$12",
            features: [
              "All Styles & Models",
              "Commercial License",
              "Generation Privacy: Public",
              "Support Level: Standard",
              "New Feature Access: Standard",
            ],
          },
          {
            id: 2,
            name: "Standard",
            description: "Enhanced features for creators seeking more flexibility and support.",
            price: "$30",
            yearlyPrice: "$25",
            features: [
              "All Styles & Models",
              "Commercial License",
              "Generation Privacy: Public",
              "Support Level: Standard",
              "New Feature Access: Early",
            ],
          },
          {
            id: 3,
            name: "Professional",
            description: "Advanced tools and privacy for professionals maximizing productivity.",
            price: "$60",
            yearlyPrice: "$50",
            features: [
              "Access to Ideate Studio",
              "All Styles & Models",
              "Commercial License",
              "Generation Privacy: Private",
              "Support Level: Priority",
              "New Feature Access: Early",
              "Higher priority in generation queue",
            ],
          },
          {
            id: 4,
            name: "Unlimited",
            description: "Unlimited access and top-tier tools for high-demand creators and teams.",
            price: "$120",
            yearlyPrice: "$100",
            features: [
              "Unlimited Realtime Generations",
              "Access to Ideate Studio",
              "All Styles & Models",
              "Commercial License",
              "Generation Privacy: Private",
              "Support Level: Dedicated",
              "New Feature Access: Earliest",
              "Higher priority in generation queue",
            ],
          },
        ],
      };
    },
    computed: {
      displayedPlans() {
        // Dynamically display plans based on the toggle (monthly/yearly)
        return this.plans.map((plan) => ({
          ...plan,
          price: this.isMonthly ? plan.price : plan.yearlyPrice,
        }));
      },
    },
    methods: {
      switchToMonthly() {
        this.isMonthly = true;
      },
      switchToYearly() {
        this.isMonthly = false;
      },
      selectPlan(planId) {
        this.selectedPlan = planId;
      },
    },
  };
  </script>
  
  <style scoped>
  /* General Styles */
  .subscription-page {
    padding: 20px;
    background: linear-gradient(120deg, #f8f9fa, #e9ecef);
    min-height: 100vh;
    text-align: center;
  }
  
  /* Toggle Button */
  .toggle-container {
    margin-bottom: 20px;
  }
  
  .toggle {
    display: inline-flex;
    background: #e9ecef;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }
  
  .toggle button {
    background: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    color: #495057;
    transition: all 0.3s;
  }
  
  .toggle button.active {
    background: #004e92;
    color: white;
    font-weight: bold;
  }
  
  .discount {
    background: #ffcb42;
    border-radius: 5px;
    padding: 2px 5px;
    font-size: 12px;
    color: white;
    margin-left: 5px;
  }
  
  /* Subscription Cards */
  .subscription-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .subscription-card {
    background: white;
    border-radius: 10px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: left;
    position: relative;
    display: grid;
    grid-template-rows: auto auto 1fr auto; /* Header, Price, Features, Button */
  }
  
  .subscription-card.selected {
    background: #004e92;
    color: white;
    border: 2px solid #ffcb42;
  }
  
  .plan-header h2 {
    font-size: 18px;
    margin: 0;
  }
  
  .plan-header p {
    font-size: 14px;
    margin: 5px 0 10px;
  }
  
  .plan-price h3 {
    font-size: 24px;
    margin: 10px 0;
  }
  
  .plan-price p {
    font-size: 12px;
    color: gray;
  }
  
  /* Features Section */
  .plan-features {
    margin-top: 10px;
  }
  
  .plan-features h4 {
    font-size: 16px;
    margin: 10px 0;
  }
  
  .plan-features ul {
    padding-left: 20px;
    font-size: 14px;
  }
  
  .plan-features ul li {
    margin-bottom: 5px;
  }
  
  /* Button Alignment */
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .get-started {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background: black;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .get-started:hover {
    background: #333;
  }
  </style>