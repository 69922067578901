<template>
    <div class="registration-page">
      <div class="registration-container">
        <h1>Register</h1>
        <form @submit.prevent="handleRegister">
          <!-- Name Input -->
          <div class="form-group">
            <label for="name">Full Name:</label>
            <input
              type="text"
              id="name"
              v-model="name"
              placeholder="Enter your full name"
              required
            />
          </div>
  
          <!-- Email Input -->
          <div class="form-group">
            <label for="email">Email:</label>
            <input
              type="email"
              id="email"
              v-model="email"
              placeholder="Enter your email"
              required
            />
          </div>
  
          <!-- Password Input -->
          <div class="form-group">
            <label for="password">Password:</label>
            <input
              type="password"
              id="password"
              v-model="password"
              placeholder="Create a password"
              required
            />
          </div>
  
          <!-- Confirm Password Input -->
          <div class="form-group">
            <label for="confirm-password">Confirm Password:</label>
            <input
              type="password"
              id="confirm-password"
              v-model="confirmPassword"
              placeholder="Confirm your password"
              required
            />
          </div>
  
          <!-- Terms and Conditions -->
          <div class="form-group terms">
            <input
              type="checkbox"
              id="terms"
              v-model="termsAccepted"
              required
            />
            <label for="terms">
              I accept the <a href="#">terms and conditions</a>.
            </label>
          </div>
  
          <!-- Submit Button -->
          <button type="submit" class="register-button">Register</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "RegistrationPage",
    data() {
      return {
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        termsAccepted: false,
      };
    },
    methods: {
      handleRegister() {
        if (this.password !== this.confirmPassword) {
          alert("Passwords do not match!");
          return;
        }
  
        if (!this.termsAccepted) {
          alert("You must accept the terms and conditions.");
          return;
        }
  
        alert(`Successfully registered as ${this.name}!`);
        // Add further registration logic here (e.g., API call)
      },
    },
  };
  </script>
  
  <style scoped>
  /* General Styles */
  .registration-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(120deg, #004e92, #000428);
  }
  
  .registration-container {
    background: white;
    padding: 30px;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  h1 {
    color: #004e92;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
    text-align: left;
  }
  
  label {
    font-size: 14px;
    color: #333;
  }
  
  input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  input:focus {
    outline: none;
    border-color: #004e92;
  }
  
  /* Terms and Conditions */
  .terms {
    display: flex;
    align-items: center;
    gap: 8px; /* Space between checkbox and text */
    margin-top: 10px;
  }
  
  .terms input {
    margin: 0;
    width: 16px; /* Adjust width of the checkbox */
    height: 16px; /* Adjust height of the checkbox */
  }
  
  .terms label {
    font-size: 14px;
    color: #333;
  }
  
  .terms a {
    color: #004e92;
    text-decoration: none;
  }
  
  .terms a:hover {
    text-decoration: underline;
  }
  
  /* Submit Button */
  .register-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: white;
    background: #004e92;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .register-button:hover {
    background: #003973;
  }
  </style>