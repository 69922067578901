import { createRouter, createWebHistory } from "vue-router";
import HomePage from "./HomePage.vue";
import ServicesPage from "./ServicesPage.vue";
import AISolutionsPage from "./AISolutionsPage.vue";
import IoTSolutionsPage from "./IoTSolutionsPage.vue";
import HologramSolutionsPage from "./HologramSolutionsPage.vue";
import ARExperiencesPage from "./ARExperiencesPage.vue";
import VRExperiencesPage from "./VRExperiencesPage.vue";
import WebApplicationsPage from "./WebApplicationsPage.vue";
import AboutPage from "./AboutPage.vue";
import RegistrationPage from "./RegistrationPage.vue";
import LoginPage from "./LoginPage.vue";
import SubscriptionPage from "./SubscriptionPage.vue";

const routes = [
  { path: "/", name: "Home", component: HomePage },
  { path: "/services", name: "Services", component: ServicesPage },
  { path: "/services/ai-solutions", name: "AISolutions", component: AISolutionsPage },
  { path: "/services/iot-solutions", name: "IoTSolutions", component: IoTSolutionsPage },
  { path: "/services/hologram-solutions", name: "HologramSolutions", component: HologramSolutionsPage },
  { path: "/services/ar-experiences", name: "ARExperiences", component: ARExperiencesPage },
  { path: "/services/vr-experiences", name: "VRExperiences", component: VRExperiencesPage },
  { path: "/services/web-applications", name: "WebApplications", component: WebApplicationsPage },
  { path: "/about", name: "About", component: AboutPage },
  { path: "/register", name: "Register", component: RegistrationPage },
  { path: "/login", name: "Login", component: LoginPage },
  { path: "/subscriptions", name: "Subscriptions", component: SubscriptionPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;