<template>
  <div id="app">
    <!-- Navigation Bar -->
    <nav>
      <ul class="nav-links">
        <li><router-link to="/">Home</router-link></li>
        <li class="dropdown">
          <span>Services</span>
          <ul class="dropdown-menu">
            <li><router-link to="/services/ai-solutions">AI Solutions</router-link></li>
            <li><router-link to="/services/iot-solutions">IoT Solutions</router-link></li>
            <li><router-link to="/services/hologram-solutions">Hologram Solutions</router-link></li>
            <li><router-link to="/services/on-ground-installations">On Ground Installations</router-link></li>
            <li><router-link to="/services/ar-experiences">Augmented Reality Experiences</router-link></li>
            <li><router-link to="/services/vr-experiences">Virtual Reality Experiences</router-link></li>
          </ul>
        </li>
        <li><router-link to="/about">About</router-link></li>
        <li><router-link to="/subscriptions">Subscription</router-link></li>
        <li><router-link to="/register">Register</router-link></li>
        <li><router-link to="/login">Login</router-link></li>
      </ul>
      <div class="contact-info">
        <span>Email: <a href="mailto:contact@techsolutions.com">contact@techsolutions.com</a></span>
        <span>Phone: <a href="tel:+1234567890">+123 456 7890</a></span>
      </div>
    </nav>
    <router-view />
  </div>
</template>

<style>
/* Navigation Bar */
nav {
  background-color: rgba(0, 78, 146, 0.9);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-links li {
  position: relative;
  margin: 0 15px;
}

.nav-links a,
nav span {
  color: white;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}

.nav-links a:hover,
nav span:hover {
  text-decoration: underline;
}

/* Dropdown Menu */
.dropdown {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  color: #004e92;
  list-style: none;
  padding: 10px 0;
  border-radius: 5px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  z-index: 200;
  min-width: 200px;
}

.dropdown-menu li {
  padding: 10px 20px;
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}

.dropdown-menu a {
  color: #004e92;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

/* Contact Info Styling */
.contact-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.contact-info a {
  color: #ffcc00; /* Highlighted color for contact links */
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}
</style>